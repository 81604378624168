import React from "react";
import "./App.css";
import { Route, Router, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import HistoryPage from "./pages/HistoryPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ResumeParserLayout from "./common/ResumeParser";
import SignUpWithHeader from "./pages/SignUpWithHeader";
import ConversionWizardMiddlepage from "./components/ConversionWizardMiddlepage";
import PageNotFound from "./pages/PageNotFound";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

const customHistory = createBrowserHistory();
const CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID as string;

function App() {
  return (
    <div className="App">
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <Router location={customHistory.location} navigator={customHistory}>
          <Routes>
            <Route path="/conversion-wizard" element={<ResumeParserLayout />} />
            <Route
              path="/conversion-wizard/inside"
              element={
                <ResumeParserLayout children={<ConversionWizardMiddlepage />} />
              }
            />
            <Route path="/" element={<ResumeParserLayout />} />
            <Route
              path="/history"
              element={<ResumeParserLayout children={<HistoryPage />} />}
            />
            <Route path="/sign-up" element={<SignUpWithHeader />} />
            <Route path="/terms-of-use" element={<TermsOfUsePage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="sitemap.xml"/>
            <Route path="pages-sitemap.xml"/>
            <Route path="*" Component={PageNotFound} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
